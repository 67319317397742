import React from 'react'
import NavbarDashboard from './NavbarDashboard'
import chevronRightImg from '../assets/images/chevron-right.png';

const Dashboard = () => {
    return (
        <div className='dashboard'>
            <NavbarDashboard />

            <div className='toolbar'>
                <div className='container'>
                    <div className='d-flex justify-between items-center'>
                        <div>
                            <input type='email' placeholder='Search by email' />
                        </div>
                        <div>
                            <select name="users" id="users">
                                <option value="volvo">Newest</option>
                                <option value="saab">Oldest</option>
                            </select>
                        </div>
                    </div>

                    <div className='table'>
                        <table>
                            <tr>
                                <th></th>
                                <th>Email</th>
                                <th>Subscription type</th>
                                <th>Subscription date</th>
                                <th>Number of generated images</th>
                                <th></th>

                            </tr>

                            <tr>
                                <td>1234</td>
                                <td className='email'>exam@ema.com</td>
                                <td>Starter</td>
                                <td>12/12/1999</td>
                                <td>124</td>
                                <td>
                                    <img
                                        src={chevronRightImg}
                                        alt="chevronRightImg"

                                    /></td>
                            </tr>


                            <tr>
                                <td>1234</td>
                                <td>example@email.com</td>
                                <td>Starter</td>
                                <td>12/12/1999</td>
                                <td>124</td>
                                <td>
                                    <img
                                        src={chevronRightImg}
                                        alt="chevronRightImg"

                                    /></td>
                            </tr>

                            <tr>
                                <td>1234</td>
                                <td>examvgtgple@email.com</td>
                                <td>Starter</td>
                                <td>12/12/1999</td>
                                <td>124</td>
                                <td>
                                    <img
                                        src={chevronRightImg}
                                        alt="chevronRightImg"

                                    /></td>
                            </tr>


                            <tr>
                                <td>1234</td>
                                <td>examplgvggttge@email.com</td>
                                <td>Starter Starter</td>
                                <td>12/12/1999</td>
                                <td>124</td>
                                <td>
                                    <img
                                        src={chevronRightImg}
                                        alt="chevronRightImg"

                                    /></td>
                            </tr>


                            <tr>
                                <td>1234</td>
                                <td>example@email.com</td>
                                <td>Starter</td>
                                <td>12/12/1999</td>
                                <td>124</td>
                                <td>
                                    <img
                                        src={chevronRightImg}
                                        alt="chevronRightImg"

                                    /></td>
                            </tr>


                            <tr>
                                <td>1234</td>
                                <td>example@email.com</td>
                                <td>Starter</td>
                                <td>12/12/1999</td>
                                <td>124</td>
                                <td>
                                    <img
                                        src={chevronRightImg}
                                        alt="chevronRightImg"

                                    /></td>
                            </tr>


                            <tr>
                                <td>1234</td>
                                <td>example@email.com</td>
                                <td>Starter</td>
                                <td>12/12/1999</td>
                                <td>124</td>
                                <td>
                                    <img
                                        src={chevronRightImg}
                                        alt="chevronRightImg"

                                    /></td>
                            </tr>

                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard