import React from 'react'
import { useNavigate } from 'react-router';
const Login = () => {
    const navigate = useNavigate();
    return (
        <div className='login-signup'>
            <div className='container'>
                <div>
                    <h2 className='text-center'>Design MAI</h2>
                    <p className='text-center'>Change the way your home looks in instant</p>
                </div>


                {/* Signup/Login */}
                <div className='box'>
                    <h3>Signup/Login</h3>

                    <div className='form-group'>
                        <div>Email</div>
                        <input type='email' placeholder='you@example.com' />
                    </div>
                </div>



                
                {/* Select password */}
                {/* <div className='box'>
                    <h3>Select password</h3>
                    <p>Select a password to create account</p>

                    <div className='form-group'>
                        <div>Password</div>
                        <input type='password' placeholder='8 characters at least' />
                    </div>
                </div> */}


                <button className='btn-gradiant border-0 py-10 px-15 pointer' onClick={() => navigate('/password')}>Continue</button>

                <p className='cpr'>By creating account you have accepted the terms and conditions</p>

            </div>
        </div>
    )
}

export default Login