import { BrowserRouter, Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Upload from './components/Upload';
import Home from './components/Home';
import Dashboard from './components/Dashboard';
import UsrerDashboardDetail from './components/UsrerDashboardDetail';
import Login from './components/Login';
import Password from './components/Password';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/dashboard-detail" element={<UsrerDashboardDetail />} />
        <Route path="/upload" element={<Upload />} />
        <Route path="/login" element={<Login />} />
        <Route path="/password" element={<Password />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
