import React, { useState } from 'react'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import originalImg from '../assets/images/original.jpg';
import generatedImg from '../assets/images/genereted.jpg';
import shareImg from '../assets/images/share.png';
import backImg from '../assets/images/back.png';
import MobileUpload from './MobileUpload';
import NavbarUpload from './NavbarUpload';
const Upload = () => {

    const [splitPosition, setSplitPosition] = useState(50);

    const handleMouseMove = (e) => {
        const { clientX, target } = e;
        const { left, width } = target.getBoundingClientRect();
        const newSplitPosition = ((clientX - left) / width) * 100;
        setSplitPosition(newSplitPosition);
    };
    return (
        <div>
            <NavbarUpload />

            <div class="main-container">
                <Sidebar />

                <div class="content">

                    <div className='d-flex justify-between items-center upload-info p-15'>
                        <div className='d-flex'>
                            <div className='item mr-8'>Bedroom</div>
                            <div className='item mr-8'>Industrial</div>
                            <div className='item mr-8'>$1000 - $2000</div>
                        </div>
                    </div>


                    <div className='d-flex'
                        style={{
                            position: 'relative',
                            width: 'auto',
                            height: '586px',
                            overflow: 'hidden',
                            cursor: 'pointer',
                            borderRadius: '24px'
                        }}
                        onMouseMove={handleMouseMove}
                    >
                        {/* تصویر اول که قسمت چپ را نشان می‌دهد */}
                        <img
                            src={originalImg}
                            alt="orginal img"
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                clipPath: `polygon(0 0, ${splitPosition}% 0, ${splitPosition}% 100%, 0 100%)`
                            }}
                        />
                        {/* تصویر دوم که قسمت راست را نشان می‌دهد */}
                        <img
                            src={generatedImg}
                            alt="Generated"
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                clipPath: `polygon(${splitPosition}% 0, 100% 0, 100% 100%, ${splitPosition}% 100%)`
                            }}
                        />

                        {/* خط تقسیم کننده */}
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: `${splitPosition}%`,
                                width: '2px',
                                height: '100%',
                                backgroundColor: '#171717',
                                pointerEvents: 'none'
                            }}
                        />
                    </div>


                    <div className='d-flex justify-between items-center upload-info p-15'>
                        <div className='d-flex'>
                            <button className='btn-gradiant border-0 py-10 px-15 pointer'>Download</button>
                        </div>

                        <div className='d-flex items-center'>
                            <div className='d-flex items-center pointer'>
                                <img
                                    src={shareImg}
                                    alt="Share"
                                    className='mr-10'

                                />
                                Share</div>
                            <div className='ml-15 pointer'>Compare</div>
                        </div>
                    </div>

                </div>

            </div>



            {/* MOBILE */}
            <MobileUpload />



        </div>
    )
}

export default Upload