import React from 'react'
import { useNavigate } from 'react-router';

const NavbarUpload = () => {
    const navigate = useNavigate();

    return (
        <div class="navbar gray-text nav-hidden">
            <div class="navbar-container">
                <button class="navbar-toggle">
                    <span class="navbar-icon"></span>
                    <span class="navbar-icon"></span>
                    <span class="navbar-icon"></span>
                </button>

                <div class="d-flex items-center">
                    <div class="logo">Design MAI</div>
                </div>
                <ul class="navbar-menu">
                    <li class="navbar-item"><a href="#home">Links</a></li>
                    <li class="navbar-item"><a href="#home">Links</a></li>
                    <li class="navbar-item"><a href="#home">Links</a></li>
                </ul>
                <div class="d-flex navbar-profile items-center">
                    <div><img src="assets/img/profile.png" alt="" class="mr-16" /></div>
                    <button class="btn-gradiant border-0 py-10 px-15 pointer" onClick={() => navigate('/login')}>Signup/Login</button>
                </div>
            </div>
        </div>
    )
}

export default NavbarUpload