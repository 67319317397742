import React, { useState } from 'react'
import Navbar from './Navbar'

import wixImg from '../assets/images/wix.png';
import googleImg from '../assets/images/google.png';
import primeImg from '../assets/images/prime.png';
import paypalImg from '../assets/images/paypal.png';
import ebayImg from '../assets/images/ebay.png';

import inpaintingImg from '../assets/images/inpainting-3d.png';
import roomStyleImg from '../assets/images/room-style-home.png';
import customizableImg from '../assets/images/customizable.png';

import firstFeautersImg from '../assets/images/first-feauters.png';
import secondFeautersImg from '../assets/images/second-feauters.png';
import thirdFeautersImg from '../assets/images/third-feauters.png';

import uploadBoxImg from '../assets/images/upload-box.png';
import simpleUploadImg from '../assets/images/simple-upload.png';

import pencilImg from '../assets/images/pencil.png';


import planList1Img from '../assets/images/planlist1.png';
import planList2Img from '../assets/images/planlist2.png';
import planList3Img from '../assets/images/planlist3.png';
import planList4Img from '../assets/images/planlist4.png';
import planList5Img from '../assets/images/planlist5.png';
import planList6Img from '../assets/images/planlist6.png';
import planList7Img from '../assets/images/planlist7.png';
import planList8Img from '../assets/images/planlist8.png';
import planList9Img from '../assets/images/planlist9.png';
import planList10Img from '../assets/images/planlist10.png';
import planList11Img from '../assets/images/planlist11.png';
import planList12Img from '../assets/images/planlist12.png';

import plusImg from '../assets/images/plus.png';

import instaImg from '../assets/images/insta.png';
import tweeterImg from '../assets/images/tweeter.png';
import youtubeImg from '../assets/images/youtube.png';
import facebookImg from '../assets/images/facebook.png';
import pinterestImg from '../assets/images/pinterest.png';
import linkedinImg from '../assets/images/linkedin.png';
import { useNavigate } from 'react-router';


const faqs = [
    { question: 'What is the best tool for staging Home?', answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' },
    { question: 'How can I customize my room style?', answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' },
    { question: 'Can I get a refund if I am not satisfied?', answer: 'Yes, we offer a 30-day money-back guarantee. If you are not satisfied with our product, you can request a full refund within 30 days of purchase.' },
    { question: 'Do you offer customer support?', answer: 'Yes, we offer 24/7 customer support. You can reach us via email, phone, or live chat at any time.' },
    { question: 'How long does it take to set up the tool?', answer: 'The setup process is quick and easy. It typically takes about 10-15 minutes to complete the initial setup and start using the tool.' },
    { question: 'Are there any tutorials available?', answer: 'Yes, we provide a variety of tutorials and guides to help you get started. You can find these resources in the support section of our website.' },
    { question: 'Can I use the tool on multiple devices?', answer: 'Yes, our tool is compatible with multiple devices. You can use it on your desktop, laptop, tablet, and smartphone.' },
];



const Home = () => {
    const navigate = useNavigate();
    const [openIndexes, setOpenIndexes] = useState([]);

    const toggleFAQ = index => {
        setOpenIndexes(prevState =>
            prevState.includes(index)
                ? prevState.filter(i => i !== index)
                : [...prevState, index]
        );
    };

    return (
        <div className='bg-abs-top'>
            <Navbar />

            <div className='introduce '>
                <div className='container'>
                    <h1>Design Your Dream House At Glance <br />With <span className='text-purple'>Design MAI</span></h1>

                    <p className='sub-intro my-32'>By using Design MAI generate photo realistic image of your dearm house within seconds</p>

                    <button className='btn-gradiant border-0 py-10 px-15 mb-32 pointer' onClick={() => navigate('/upload')}>Try For Free Now</button>

                    <div className='donators'>
                        <div>
                            <img
                                src={wixImg}
                                alt="wix"
                                className='mr-10'

                            />
                        </div>
                        <div>
                            <img
                                src={googleImg}
                                alt="google"
                                className='mr-10'

                            />
                        </div>
                        <div>

                            <img
                                src={primeImg}
                                alt="prime"
                                className='mr-10'

                            />
                        </div>
                        <div>
                            <img
                                src={paypalImg}
                                alt="paypal"
                                className='mr-10'

                            />
                        </div>
                        <div>
                            <img
                                src={ebayImg}
                                alt="ebay"
                                className='mr-10'

                            />
                        </div>
                    </div>


                    <div className='services'>
                        <div className='box'>
                            <div className='d-flex'>
                                <div>
                                    <img
                                        src={inpaintingImg}
                                        alt="inpaintingImg"
                                        className='mr-16'

                                    />
                                </div>
                                <div className='title'>30 inpainting prompt</div>
                            </div>
                            <div className='sub-title'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</div>
                        </div>

                        <div className='box'>
                            <div className='d-flex'>
                                <div>
                                    <img
                                        src={roomStyleImg}
                                        alt="customizableImg"
                                        className='mr-16'

                                    />
                                </div>
                                <div className='title'>+50 Styles & Room Types</div>
                            </div>
                            <div className='sub-title'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</div>
                        </div>

                        <div className='box'>
                            <div className='d-flex'>
                                <div>
                                    <img
                                        src={customizableImg}
                                        alt="customizableImg"
                                        className='mr-16'

                                    />
                                </div>
                                <div className='title'>Highly customizable</div>
                            </div>
                            <div className='sub-title'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</div>
                        </div>
                    </div>

                </div>
            </div>


            <div className='features'>
                <div className='first'>
                    <div className='container'>
                        <div className='d-flex'>
                            <div>
                                <img
                                    src={firstFeautersImg}
                                    alt="firstFeautersImg"
                                    className='mr-32'

                                />
                            </div>
                            <div className='info'>
                                <h2><span>All Room Type & Styles</span> To Design Your Dream House</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>

                                <button className='btn-gradiant border-0 py-10 px-15 pointer' onClick={() => navigate('/upload')}>Try For Free Now</button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='second'>
                    <div className='container'>
                        <div className='d-flex'>
                            <div className='d-none-d'>
                                <img
                                    src={secondFeautersImg}
                                    alt="firstFeautersImg"
                                    className='mr-32'

                                />
                            </div>
                            <div className='info'>
                                <h2><span>All Room Type & Styles</span> To Design Your Dream House</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>

                                <button className='btn-gradiant border-0 py-10 px-15 pointer' onClick={() => navigate('/upload')}>Try For Free Now</button>
                            </div>

                            <div className='d-none-m'>
                                <img
                                    src={secondFeautersImg}
                                    alt="secondFeautersImg"
                                    className='ml-32'

                                />
                            </div>

                        </div>
                    </div>
                </div>


                <div className='third'>
                    <div className='container'>
                        <div className='d-flex'>
                            <div>
                                <img
                                    src={thirdFeautersImg}
                                    alt="thirdFeautersImg"
                                    className='mr-32'

                                />
                            </div>
                            <div className='info'>
                                <h2><span>All Room Type & Styles</span> To Design Your Dream House</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>

                                <button className='btn-gradiant border-0 py-10 px-15 pointer' onClick={() => navigate('/upload')}>Try For Free Now</button>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <div className='how-to-use'>
                <div className='container'>
                    <div className='d-flex justify-center mb-30'>
                        <h3>As Easy As Eating A Sweet Pie</h3>
                    </div>

                    <div className='d-flex justify-around mt-32 mb-32 boxes'>
                        <div className='box active'>
                            <div className='d-flex items-center text-fill'>
                                <div>
                                    <img
                                        src={roomStyleImg}
                                        alt="customizableImg"
                                        className='mr-16'

                                    />
                                </div>
                                <div className="title">Upload Image</div>
                            </div>

                            <p>
                                Upload your image or use one of our samples
                            </p>
                        </div>


                        <div className='box'>
                            <div className='d-flex items-center text-fill'>
                                <div>
                                    <img
                                        src={roomStyleImg}
                                        alt="customizableImg"
                                        className='mr-16'

                                    />
                                </div>
                                <div className="title">Set preferences</div>
                            </div>

                            <p>
                                Select your space type, style, and budget
                            </p>
                        </div>


                        <div className='box'>
                            <div className='d-flex items-center text-fill'>
                                <div>
                                    <img
                                        src={pencilImg}
                                        alt="pencilImg"
                                        className='mr-16'

                                    />
                                </div>
                                <div className="title">Write instructions</div>
                            </div>

                            <p>
                                Write instructions and your dream house is at your hands
                            </p>
                        </div>


                    </div>

                    <div className='d-flex justify-center mt-64 upload-container'>
                        <div className='upload-box'>
                            <h3 className='upload-title'>Please select your image to upload</h3>

                            <div>
                                <img
                                    src={uploadBoxImg}
                                    alt="uploadBoxImg"
                                    className='mt-32'
                                />
                            </div>

                            <div className='choose-file-btn mt-16'>
                                Choose File
                            </div>

                            <div className='sub-up'>
                                <p>or use our samples</p>

                                <div>
                                    <img
                                        src={simpleUploadImg}
                                        alt="simpleUploadImg"
                                        className='mt-8'
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className='plans'>
                <div className='container'>
                    <div className='d-flex justify-center pln'>
                        <h3>Design Your Dream House With Click</h3>
                    </div>

                    <div className='d-flex justify-between plans-column'>
                        <div className='plan'>
                            <div>Starter</div>
                            <div>Free/Billed yearly</div>

                            <button className='btn-gradiant border-0 py-10 width-100 mt-30 mb-10 pointer' onClick={() => navigate('/upload')}>Try For Free Now</button>

                            <ul>
                                <li>
                                    <img
                                        src={planList1Img}
                                        alt="planList1Img"
                                        className='mr-12'
                                    />
                                    Upload up to 3 pictures
                                </li>
                                <li>
                                    <img
                                        src={planList2Img}
                                        alt="planList1Img"
                                        className='mr-12'
                                    />
                                    Lifetime limits across generations</li>
                                <li>
                                    <img
                                        src={planList3Img}
                                        alt="planList1Img"
                                        className='mr-12'
                                    />
                                    Limited access to all features</li>
                                <li>
                                    <img
                                        src={planList4Img}
                                        alt="planList1Img"
                                        className='mr-12'
                                    />
                                    Basic support</li>
                            </ul>
                        </div>

                        <div className='plan'>
                            <div>Starter</div>
                            <div>Free/Billed yearly</div>

                            <button className='btn-gradiant border-0 py-10 width-100 mt-30 mb-10 pointer' onClick={() => navigate('/upload')}>Try For Free Now</button>

                            <ul>
                                <li>
                                    <img
                                        src={planList1Img}
                                        alt="planList1Img"
                                        className='mr-12'
                                    />
                                    Upload up to 3 pictures
                                </li>
                                <li>
                                    <img
                                        src={planList2Img}
                                        alt="planList1Img"
                                        className='mr-12'
                                    />
                                    Lifetime limits across generations</li>
                                <li>
                                    <img
                                        src={planList3Img}
                                        alt="planList1Img"
                                        className='mr-12'
                                    />
                                    Limited access to all features</li>
                                <li>
                                    <img
                                        src={planList4Img}
                                        alt="planList1Img"
                                        className='mr-12'
                                    />
                                    Basic support</li>
                                <li>
                                    <img
                                        src={planList5Img}
                                        alt="planList5Img"
                                        className='mr-12'
                                    />
                                    Access 30 prompt inpainting features</li>
                                <li>
                                    <img
                                        src={planList6Img}
                                        alt="planList6Img"
                                        className='mr-12'
                                    />
                                    Download photos only with trademark</li>
                            </ul>
                        </div>

                        <div className='plan'>
                            <div>Starter</div>
                            <div>Free/Billed yearly</div>

                            <button className='btn-gradiant border-0 py-10 width-100 mt-30 mb-10 pointer' onClick={() => navigate('/upload')}>Try For Free Now</button>

                            <ul>
                                <li>
                                    <img
                                        src={planList1Img}
                                        alt="planList1Img"
                                        className='mr-12'
                                    />
                                    Upload up to 3 pictures
                                </li>
                                <li>
                                    <img
                                        src={planList2Img}
                                        alt="planList1Img"
                                        className='mr-12'
                                    />
                                    Lifetime limits across generations</li>
                                <li>
                                    <img
                                        src={planList3Img}
                                        alt="planList1Img"
                                        className='mr-12'
                                    />
                                    Limited access to all features</li>
                                <li>
                                    <img
                                        src={planList4Img}
                                        alt="planList1Img"
                                        className='mr-12'
                                    />
                                    Basic support</li>
                                <li>
                                    <img
                                        src={planList5Img}
                                        alt="planList5Img"
                                        className='mr-12'
                                    />
                                    Access 30 prompt inpainting features</li>
                                <li>
                                    <img
                                        src={planList6Img}
                                        alt="planList6Img"
                                        className='mr-12'
                                    />
                                    Download photos only with trademark</li>

                                <li>
                                    <img
                                        src={planList7Img}
                                        alt="planList7Img"
                                        className='mr-12'
                                    />
                                    Commercial license</li>

                                <li>
                                    <img
                                        src={planList8Img}
                                        alt="planList8Img"
                                        className='mr-12'
                                    />
                                    Unlimited all space types and styles</li>

                                <li>
                                    <img
                                        src={planList9Img}
                                        alt="planList9Img"
                                        className='mr-12'
                                    />
                                    Priority generation time</li>

                                <li>
                                    <img
                                        src={planList10Img}
                                        alt="planList10Img"
                                        className='mr-12'
                                    />
                                    Private mode</li>

                                <li>
                                    <img
                                        src={planList11Img}
                                        alt="planList11Img"
                                        className='mr-12'
                                    />
                                    Generation history</li>

                                <li>
                                    <img
                                        src={planList12Img}
                                        alt="planList12Img"
                                        className='mr-12'
                                    />
                                    Priority access to new features</li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <div className='faq'>
                <div className='container'>
                    <div className='d-flex justify-center mb-25'>
                        <h3>Your Frequently Asked Questions</h3>
                    </div>

                    {faqs.map((faq, index) => (
                        <div className='item' key={index}>
                            <div className='question pointer' onClick={() => toggleFAQ(index)}>
                                <div className='q-title'>{faq.question}</div>
                                <div>
                                    <img
                                        src={plusImg}
                                        alt="plusImg"
                                        style={{
                                            transform: openIndexes.includes(index) ? 'rotate(45deg)' : 'rotate(0deg)',
                                            transition: 'transform 0.3s ease',
                                        }}
                                    />
                                </div>
                            </div>

                            <div
                                className='answer'
                                style={{
                                    maxHeight: openIndexes.includes(index) ? '200px' : '0',
                                    overflow: 'hidden',
                                    transition: 'max-height 0.5s ease',
                                }}
                            >
                                {faq.answer}
                            </div>
                        </div>
                    ))}



                </div>
            </div>

            <div className='last-cta'>
                <div className='container'>
                    <div className='d-flex justify-center text-center'>
                        <h3>Design Your Dream House At Glance <br />With<span> Design MAI</span></h3>
                    </div>

                    <div className='d-flex justify-center text-center mt-32'>
                        <button className='btn-gradiant border-0 py-10 px-15 mb-32 fs-16 fw-500 pointer' onClick={() => navigate('/upload')}>Try For Free Now</button>
                    </div>
                </div>
            </div>


            <div className='footer'>
                <div className='container'>
                    <div className='d-flex justify-between footer-column'>
                        <div>
                            <h2>Design MAI</h2>
                            <p className='mt-25'>Design Your Dream House At Glance With Design MAI</p>
                        </div>

                        <div className='d-flex justify-between links'>
                            <div>
                                <h3>Links</h3>
                                <ul>
                                    <li>Link</li>
                                    <li>Link</li>
                                    <li>Link</li>
                                    <li>Link</li>
                                    <li>API</li>
                                </ul>
                            </div>


                            <div>
                                <h3>Links</h3>
                                <ul>
                                    <li>Link</li>
                                    <li>Link</li>
                                    <li>Link</li>
                                    <li>Link</li>
                                    <li>API</li>
                                </ul>
                            </div>

                            <div>
                                <h3>Links</h3>
                                <ul>
                                    <li>Link</li>
                                    <li>Link</li>
                                    <li>Link</li>
                                    <li>Link</li>
                                    <li>API</li>
                                </ul>
                            </div>

                            <div>
                                <h3>Links</h3>
                                <ul>
                                    <li>Link</li>
                                    <li>Link</li>
                                    <li>Link</li>
                                    <li>Link</li>
                                    <li>API</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className='d-flex items-center copyright'>
                        <div>
                            <ul className='d-flex sub-footer'>
                                <li>Copyrigth Design MAI</li> <span> | </span>
                                <li>Privecy policy</li> <span> | </span>
                                <li>Security</li> <span> | </span>
                                <li>Terms of service</li>
                            </ul>
                        </div>
                        <div className='d-flex items-center social'>
                            <img
                                src={instaImg}
                                alt="instaImg"
                                className='mr-25'
                            />

                            <img
                                src={youtubeImg}
                                alt="youtubeImg"
                                className='mr-25'
                            />

                            <img
                                src={tweeterImg}
                                alt="tweeterImg"
                                className='mr-25'
                            />

                            <img
                                src={facebookImg}
                                alt="facebookImg"
                                className='mr-25'
                            />

                            <img
                                src={pinterestImg}
                                alt="pinterestImg"
                                className='mr-25'
                            />

                            <img
                                src={linkedinImg}
                                alt="linkedinImg"
                                className='mr-25'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home