import React, { useState } from 'react';
import downloadImg from '../assets/images/download.png';
import downloadBoxImg from '../assets/images/download-box.png';
import preferencesImg from '../assets/images/preferences.png';
import chevronRightImg from '../assets/images/chevron-right.png';
import inpaintingPicImg from '../assets/images/inpainting-pic.png';
import backImg from '../assets/images/back.png';
import bedroomtImg from '../assets/images/bedroom.png';
import roomtImg from '../assets/images/room.png';
import styleRoomtImg from '../assets/images/style-room.png';
import styleRoom1tImg from '../assets/images/style-room-1.png';
import sampleImg from '../assets/images/sample-upload.png';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const MobileUpload = () => {
    const [currentSection, setCurrentSection] = useState(0);
    const [isSpaceOpen, setIsSpaceOpen] = useState(false);
    const [isStyleOpen, setIsStyleOpen] = useState(false);
    const [isBudgetOpen, setIsBudgetOpen] = useState(false);
    const [budgetRange, setBudgetRange] = useState([0, 100]);

    const handleNext = () => {
        if (currentSection < sections.length - 1) {
            setCurrentSection(currentSection + 1);
        }
    };

    const handleBack = () => {
        if (currentSection > 0) {
            setCurrentSection(currentSection - 1);
        }
    };

    const handleToggleSpace = () => {
        setIsSpaceOpen(!isSpaceOpen);
        if (!isSpaceOpen) {
            setIsStyleOpen(false);
            setIsBudgetOpen(false);
        }
    };

    const handleToggleStyle = () => {
        setIsStyleOpen(!isStyleOpen);
        if (!isStyleOpen) {
            setIsSpaceOpen(false);
            setIsBudgetOpen(false);
        }
    };

    const handleToggleBudget = () => {
        setIsBudgetOpen(!isBudgetOpen);
        if (!isBudgetOpen) {
            setIsSpaceOpen(false);
            setIsStyleOpen(false);
        }
    };

    const handleBudgetChange = (value) => {
        setBudgetRange(value);
    };

    const closeAllDropdowns = () => {
        setIsSpaceOpen(false);
        setIsStyleOpen(false);
        setIsBudgetOpen(false);
    };


    const sections = [
        {
            title: "Upload Images",
            content: (
                <div class="upload-images-section mb-16 pb-10">
                    <div class="d-flex items-center mb-16">
                        <div>Upload Images</div>
                    </div>
                    <div class="download-box d-flex flex-column justify-center items-center">
                        <div><img src={downloadBoxImg} alt="" class="mb-16" /></div>
                        <div class="choose-image-btn">Choose Image</div>
                        <div className='text-gray mt-20'>or use our samples</div>

                        <div>
                            <img src={sampleImg} alt="" class="mt-16" />
                        </div>
                    </div>

                </div>
            )
        },
        {
            title: "Set Preferences",
            content: (
                <div class="preferences-dropdown">
                    <div class="d-flex items-center mb-16">
                        <div>Set Preferences</div>
                    </div>
                    <div class="d-flex item items-center justify-between p-16 mb-16" onClick={handleToggleSpace}>
                        <div>Space</div>
                        <div><img src={chevronRightImg} alt="" /></div>
                    </div>
                    {isSpaceOpen && (
                        <div class="sidebar-drop">
                            <div className='space'>
                                <div className='mb-16'>Select your preferred room type</div>
                                <div className='d-flex space-body mb-8'>
                                    <div className='space-box' onClick={closeAllDropdowns}>
                                        <div><img src={bedroomtImg} alt="" /></div>
                                        <div>Bedroom</div>
                                    </div>
                                    <div className='space-box' onClick={closeAllDropdowns}>
                                        <div><img src={roomtImg} alt="" /></div>
                                        <div>Living room</div>
                                    </div>
                                </div>

                                <div className='d-flex space-body mb-8'>
                                    <div className='space-box' onClick={closeAllDropdowns}>
                                        <div><img src={bedroomtImg} alt="" /></div>
                                        <div>Beedroom</div>
                                    </div>
                                    <div className='space-box' onClick={closeAllDropdowns}>
                                        <div><img src={roomtImg} alt="" /></div>
                                        <div>Living room</div>
                                    </div>
                                </div>

                                <div className='d-flex space-body mb-8'>
                                    <div className='space-box' onClick={closeAllDropdowns}>
                                        <div><img src={bedroomtImg} alt="" /></div>
                                        <div>Beedroom</div>
                                    </div>
                                    <div className='space-box' onClick={closeAllDropdowns}>
                                        <div><img src={roomtImg} alt="" /></div>
                                        <div>Living room</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div class="d-flex item items-center justify-between p-16 mb-16" onClick={handleToggleStyle}>
                        <div>Style</div>
                        <div><img src={chevronRightImg} alt="" /></div>
                    </div>
                    {isStyleOpen && (
                        <div class="sidebar-drop">
                            <div className='style'>
                                <div className='mb-16'>Select your preferred room type</div>
                                <div className='d-flex space-body mb-8'>
                                    <div className='space-box' onClick={closeAllDropdowns}>
                                        <div><img src={styleRoomtImg} alt="" /></div>
                                    </div>
                                    <div className='space-box' onClick={closeAllDropdowns}>
                                        <div><img src={styleRoom1tImg} alt="" /></div>
                                    </div>
                                </div>

                                <div className='d-flex space-body mb-8'>
                                    <div className='space-box' onClick={closeAllDropdowns}>
                                        <div><img src={styleRoomtImg} alt="" /></div>
                                    </div>
                                    <div className='space-box' onClick={closeAllDropdowns}>
                                        <div><img src={styleRoom1tImg} alt="" /></div>
                                    </div>
                                </div>

                                <div className='d-flex space-body mb-8'>
                                    <div className='space-box' onClick={closeAllDropdowns}>
                                        <div><img src={styleRoomtImg} alt="" /></div>
                                    </div>
                                    <div className='space-box' onClick={closeAllDropdowns}>
                                        <div><img src={styleRoom1tImg} alt="" /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div class="d-flex item items-center justify-between p-16 mb-16" onClick={handleToggleBudget}>
                        <div>Budget</div>
                        <div><img src={chevronRightImg} alt="" /></div>
                    </div>
                    {isBudgetOpen && (
                        <div class="sidebar-drop">
                            <div className='style'>
                                <div className='mb-16'>Select your budget</div>
                                <Slider
                                    range
                                    defaultValue={budgetRange}
                                    onChange={handleBudgetChange}
                                    min={0}
                                    max={100}
                                    step={1}
                                />
                            </div>
                        </div>
                    )}
                </div>
            )
        },
        {
            title: "Inpainting",
            content: (
                <div class="inpainting">
                    <div class="d-flex items-center mb-16">
                        <div>Mask the area you want to change</div>
                    </div>
                    <img src={inpaintingPicImg} alt="inpaintingPicImg" />

                    <div>
                        <div className='mt-16'>Select you preferred room type</div>
                        <textarea className='inpainting-textarea' rows={5} placeholder='Write some instructions for better result' />
                    </div>
                </div>
            )
        }
    ];

    return (
        <div className='mobile'>
            <div className='toolbar'>
                <div onClick={handleBack}>
                    <img src={backImg} alt="back" />
                </div>
                <div>Generate New Image</div>
            </div>

            <div className='container'>
                {sections[currentSection].content}
            </div>

            <div className='button-bar'>
                <div className='d-flex items-center' onClick={handleBack}>
                    <img src={backImg} alt="back" className='' /> Back
                </div>
                <button className='btn-gradiant border-0 py-10 px-15' onClick={handleNext}>Next</button>
            </div>
        </div>
    );
};

export default MobileUpload;
