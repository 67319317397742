import React from 'react'
import NavbarDashboard from './NavbarDashboard'
import chevronRightImg from '../assets/images/chevron-right.png';
import generetedImg from '../assets/images/genereted.png';

const UsrerDashboardDetail = () => {
    return (
        <div className='dashboard'>
            <NavbarDashboard />

            <div className='toolbar'>
                <div className='container'>

                    <h3>User Info</h3>
                    <div className='mt-30'>
                        <div className='row-detail d-flex justify-between'>
                            <div>Email</div>
                            <div>Subscription type</div>
                            <div>Subscription date</div>
                        </div>

                        <div className='row-detail d-flex justify-between'>
                            <div className='text-purple'>exam@ema.com</div>
                            <div>Starter</div>
                            <div>12/12/1999</div>
                        </div>
                    </div>

                </div>


                <div className='container'>
                    <h3 className='mt-25 mb-25'>Generated Photos</h3>
                    <div className='generated-box'>

                        <div class="d-flex justify-between items-center upload-info">
                            <div class="d-flex">
                                <div class="item mr-8">Bedroom</div>
                                <div class="item mr-8">Industrial</div>
                                <div class="item mr-8">$1000 - $2000</div>
                            </div>

                            <div>
                                Generation date:  12/12/1999
                            </div>
                        </div>

                        <div className='d-flex mt-10 pictures'>
                            <div>
                                <img
                                    src={generetedImg}
                                    alt="generetedImg"

                                />
                            </div>
                            <div>
                                <img
                                    src={generetedImg}
                                    alt="generetedImg"

                                />
                            </div>
                            <div>
                                <img
                                    src={generetedImg}
                                    alt="generetedImg"

                                />
                            </div>
                        </div>

                    </div>


                    <div className='generated-box'>

                        <div class="d-flex justify-between items-center upload-info">
                            <div class="d-flex">
                                <div class="item mr-8">Bedroom</div>
                                <div class="item mr-8">Industrial</div>
                                <div class="item mr-8">$1000 - $2000</div>
                            </div>

                            <div>
                                Generation date:  12/12/1999
                            </div>
                        </div>

                        <div className='d-flex mt-10 pictures'>
                            <div>
                                <img
                                    src={generetedImg}
                                    alt="generetedImg"

                                />
                            </div>
                            <div>
                                <img
                                    src={generetedImg}
                                    alt="generetedImg"

                                />
                            </div>
                            <div>
                                <img
                                    src={generetedImg}
                                    alt="generetedImg"

                                />
                            </div>
                        </div>

                    </div>



                    <div className='generated-box'>

                        <div class="d-flex justify-between items-center upload-info">
                            <div class="d-flex">
                                <div class="item mr-8">Bedroom</div>
                                <div class="item mr-8">Industrial</div>
                                <div class="item mr-8">$1000 - $2000</div>
                            </div>

                            <div>
                                Generation date:  12/12/1999
                            </div>
                        </div>

                        <div className='d-flex mt-10 pictures'>
                            <div>
                                <img
                                    src={generetedImg}
                                    alt="generetedImg"

                                />
                            </div>
                            <div>
                                <img
                                    src={generetedImg}
                                    alt="generetedImg"

                                />
                            </div>
                            <div>
                                <img
                                    src={generetedImg}
                                    alt="generetedImg"

                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default UsrerDashboardDetail
