import React from 'react'

const NavbarDashboard = () => {
    return (
        <div class="navbar gray-text">
            <div class="navbar-container">
                <button class="navbar-toggle">
                    <span class="navbar-icon"></span>
                    <span class="navbar-icon"></span>
                    <span class="navbar-icon"></span>
                </button>

                <div class="d-flex items-center">
                    <div><img src="assets/img/logo.png" alt="" class="mr-12" /></div>
                    <div class="logo">Design MAI</div>
                </div>
                <div class="d-flex navbar-profile items-center">
                    <div className='fs-16 pointer'>Logout</div>
                </div>
            </div>
        </div>
    )
}

export default NavbarDashboard