import React, { useState } from 'react';
import downloadImg from '../assets/images/download.png';
import downloadBoxImg from '../assets/images/download-box.png';
import preferencesImg from '../assets/images/preferences.png';
import chevronRightImg from '../assets/images/chevron-right.png';
import chevronDownImg from '../assets/images/chevron-down.png';
import chevronUpImg from '../assets/images/chevron-up.png';
import inpaintingtImg from '../assets/images/inpainting.png';

import bedroomtImg from '../assets/images/bedroom.png';
import roomtImg from '../assets/images/room.png';

import styleRoomtImg from '../assets/images/style-room.png';
import styleRoom1tImg from '../assets/images/style-room-1.png';

import backImg from '../assets/images/back.png';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const Sidebar = () => {

    const [isSpaceOpen, setIsSpaceOpen] = useState(false);
    const [isStyleOpen, setIsStyleOpen] = useState(false);
    const [isBudgetOpen, setIsBudgetOpen] = useState(false);
    const [budgetRange, setBudgetRange] = useState([0, 100]);

    console.log(budgetRange);

    const handleToggleSpace = () => {
        setIsSpaceOpen(!isSpaceOpen);
        if (!isSpaceOpen) {
            setIsStyleOpen(false);
            setIsBudgetOpen(false);
        }
    };

    const handleToggleStyle = () => {
        setIsStyleOpen(!isStyleOpen);
        if (!isStyleOpen) {
            setIsSpaceOpen(false);
            setIsBudgetOpen(false);
        }
    };

    const handleToggleBudget = () => {
        setIsBudgetOpen(!isBudgetOpen);
        if (!isBudgetOpen) {
            setIsSpaceOpen(false);
            setIsStyleOpen(false);
        }
    };

    const handleBudgetChange = (value) => {
        setBudgetRange(value);
    };

    return (
        <>
            <div class="sidebar">
                <div class="sidebar-container p-16">
                    <div class="upload-images-section mb-16 pb-10">
                        <div class="d-flex items-center mb-16">
                            <div><img src={downloadImg} alt="" class="img-title mr-16" /></div>
                            <div>Upload Images</div>
                        </div>

                        <div class="download-box d-flex flex-column justify-center items-center">
                            <div><img src={downloadBoxImg} alt="" class="mb-16" /></div>
                            <div class="choose-image-btn">Choose Image</div>
                        </div>
                    </div>

                    <div class="preferences mb-16 pb-10">
                        <div class="d-flex items-center mb-16">
                            <div><img src={preferencesImg} alt="" class="img-title mr-16" /></div>
                            <div>Set Preferences</div>
                        </div>

                        <div class="preferences-dropdown">
                            <div class="d-flex item items-center justify-between p-16 mb-16" onClick={handleToggleSpace}>
                                <div>Space</div>
                                <div><img src={chevronRightImg} alt="" /></div>
                            </div>

                            <div class="d-flex item items-center justify-between p-16 mb-16" onClick={handleToggleStyle}>
                                <div>Style</div>
                                <div><img src={chevronRightImg} alt="" /></div>
                            </div>

                            <div class="d-flex item items-center justify-between p-16 mb-16" onClick={handleToggleBudget}>
                                <div>Budget</div>
                                <div><img src={chevronRightImg} alt="" /></div>
                            </div>

                        </div>
                    </div>


                    <div class="inpainting mb-16">
                        <div class="d-flex items-center mb-16">
                            <div><img src={inpaintingtImg} class="img-title mr-16" alt="" /></div>
                            <div>Inpainting</div>
                        </div>

                        <div>
                            Mask the area and add instructions to get better results
                        </div>

                        <hr className='hr' />

                        <div className='d-flex items-center justify-between'>
                            <div className='fs-16 fw-500'>Number of generations</div>
                            <div className='counter'>
                                <div><img src={chevronDownImg} alt="" /></div>
                                <div>1</div>
                                <div><img src={chevronUpImg} alt="" /></div>
                            </div>
                        </div>
                    </div>


                    <button class="btn-sidebar">Next</button>


                </div>
            </div>

            {isSpaceOpen && (
                <div class="sidebar-drop">
                    <div className='space'>
                        <div className='mb-16'>Select you preferred room type</div>

                        <div className='d-flex space-body mb-8'>
                            <div className='space-box'>
                                <div><img src={bedroomtImg} alt="" /></div>
                                <div>Beedroom</div>
                            </div>
                            <div className='space-box'>
                                <div><img src={roomtImg} alt="" /></div>
                                <div>Living room</div>
                            </div>
                        </div>

                        <div className='d-flex space-body mb-8'>
                            <div className='space-box'>
                                <div><img src={bedroomtImg} alt="" /></div>
                                <div>Beedroom</div>
                            </div>
                            <div className='space-box'>
                                <div><img src={roomtImg} alt="" /></div>
                                <div>Living room</div>
                            </div>
                        </div>

                        <div className='d-flex space-body mb-8'>
                            <div className='space-box'>
                                <div><img src={bedroomtImg} alt="" /></div>
                                <div>Beedroom</div>
                            </div>
                            <div className='space-box'>
                                <div><img src={roomtImg} alt="" /></div>
                                <div>Living room</div>
                            </div>
                        </div>

                        <div className='d-flex space-body mb-8'>
                            <div className='space-box'>
                                <div><img src={bedroomtImg} alt="" /></div>
                                <div>Beedroom</div>
                            </div>
                            <div className='space-box'>
                                <div><img src={roomtImg} alt="" /></div>
                                <div>Living room</div>
                            </div>
                        </div>

                    </div>

                </div>
            )}

            {isStyleOpen && (
                <div class="sidebar-drop">
                    <div className='style'>
                        <div className='mb-16'>Select you preferred room type</div>

                        <div className='d-flex space-body mb-8'>
                            <div className='space-box'>
                                <div><img src={styleRoomtImg} alt="" /></div>
                            </div>
                            <div className='space-box'>
                                <div><img src={styleRoom1tImg} alt="" /></div>
                            </div>
                        </div>

                        <div className='d-flex space-body mb-8'>
                            <div className='space-box'>
                                <div><img src={styleRoomtImg} alt="" /></div>
                            </div>
                            <div className='space-box'>
                                <div><img src={styleRoom1tImg} alt="" /></div>
                            </div>
                        </div>
                    </div>
                </div>

            )}


            {isBudgetOpen && (
                <div class="sidebar-drop">
                    <div className='style'>
                        <div className='mb-16'>Select your budget</div>
                        <Slider
                            range
                            defaultValue={budgetRange}
                            onChange={handleBudgetChange}
                            min={0}
                            max={100}
                            step={1}
                        />

                    </div>
                </div>

            )}


        </>
    )
}

export default Sidebar